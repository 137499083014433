<template>
    <div>
        <iframe src="https://penilaiankinerja.bkpm.go.id:2087/public/upload/1721058786_Buku%20Panduan%20BimTek%20-%20Modul%20Peserta.pdf"
   width="100%" height="600">
   </iframe>
    </div>
  </template>
  
  <script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Materi",
  data() {
    return {
      data: [],
      token: localStorage.getItem("id_token"),
      url: localStorage.getItem("baseapi"),
      text: `
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
        richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
        brunch. Food truck quinoa nesciunt laborum eiusmod.
        `,
      activeIndexes: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Panduan Bimtek" }]);
  },
};
</script>
  
  <style scoped>
.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
}

.btn-spacing {
  margin-right: 10px;
}
</style>
  